import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import milaCircle from 'assets/images/mila-circle.png';
import bigsneezeCircle from 'assets/images/bigsneeze-circle.png';
import crittercuddlerCircle from 'assets/images/crittercuddler-circle.png';
import basicbreatherCircle from 'assets/images/basicbreather-circle.png';
import rookieparentsCircle from 'assets/images/rookieparents-circle.png';
import homewreckerCircle from 'assets/images/homewrecker-circle.png';
import mamatobeCircle from 'assets/images/mamatobe-circle.png';
import overreactorCircle from 'assets/images/overreactor-circle.png';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { FILTER_NAMES, FILTER_TYPES } from '../../../shared/enums';
import {
  selectReviewsPreview, selectReviewsPreviewError,
} from '../../features/reviews/reviewsSlice';
import generateKey from '../../../shared/utils/generateKey';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param settings
 * @param components
 * @returns {JSX.Element}
 * @constructor
 */
const ReviewColumnGridWrap = ({ settings, components }) => (
  <Slider className='review-column__grid-wrap' {...settings}>
    {React.Children.toArray(components)}
  </Slider>
);

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReviewColumn = () => {
  const reviews = useSelector(selectReviewsPreview);
  const error = useSelector(selectReviewsPreviewError);

  const icons = Object.freeze({
    [FILTER_TYPES.BB]: basicbreatherCircle,
    [FILTER_TYPES.BS]: bigsneezeCircle,
    [FILTER_TYPES.RP]: rookieparentsCircle,
    [FILTER_TYPES.CC]: crittercuddlerCircle,
    [FILTER_TYPES.HW]: homewreckerCircle,
    [FILTER_TYPES.MB]: mamatobeCircle,
    [FILTER_TYPES.OR]: overreactorCircle,
  });

  const components = reviews.map((review, index) => {
    const { type, author, text } = review;

    const variant = Object.keys(FILTER_TYPES).find(key => FILTER_TYPES[key] === type);

    return (
      <div className={`review-block__grid-item review-block__grid-item--${index}`} key={generateKey('review')}>
        <article className='review-block__item-inner'>
          <span className='review-block__item-bg' />
          <header className='review-block__header'>
            <figure className='review-block__profile-image'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source
                      srcSet={icons[type] || milaCircle}
                      type='image/webp'
                    />
                    <img src={icons[type] || milaCircle} alt={variant ? `${FILTER_NAMES[variant]}` : 'Fellow Air Breather'} />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
            <div className='review-block__title-group'>
              <h2 className='review-block__title'>{author}</h2>
              <h3 className='review-block__subtitle'>{variant ? `A ${FILTER_NAMES[variant]}` : 'Fellow Air Breather'}</h3>
            </div>
          </header>
          <div className='review-block__content'>
            <p>
              {text}
            </p>
          </div>
        </article>
      </div>
    );
  });

  const settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 6000,
    autoplay: true,
    slidesToShow: 3,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2, Infinite: true }},
      { breakpoint: 600, settings: { slidesToShow: 1, dots: true }},
    ],
  };

  return (
    <section className='review-column'>
      <div className='container'>
        <div className='review-column__quote'>
          <blockquote className='review-column__content'>
            <p>
              The whole experience with Mila has been great from unboxing
              to setting up. Everything is very intuitive and easy to use
            </p>
            <footer>Jordan B.</footer>
          </blockquote>
        </div>
      </div>
      {!error && (
        <div className='review-column__grid'>
          <div className='container'>
            <ReviewColumnGridWrap settings={settings} components={components} />
            <div className='review-column__btn'>
              <Link to='/the-wall-of-love' className='btn btn--large'>Read More Reviews</Link>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ReviewColumn;
